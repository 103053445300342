import { useState } from "react";
import "./Login.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import { Csm_Login } from "../../APIS/csmLogin";
import csmLanding from "../../assets/svgs/csm-landing.svg"

export const LoginPage = () => {
  const [password, setPassword] = useState(true);
  const [cookies, setCookie] = useCookies(["user"]);
  const [email, setEmail] = useState(cookies?.username ? cookies.username : "");
  const [pass, setPass] = useState(cookies?.Password ? cookies.Password : "");
  const [remember, setRemember] = useState(
    cookies?.remember ? cookies?.remember : false
  );
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        email,
        password: pass,
        role: "CSM",
      };
      const res = await Csm_Login(data);
      if (res) {
        if (remember) rememberCookie();
        sessionStorage.setItem("email", email);
        localStorage.setItem("email", email);
        navigate("/dashboard");
      } else {
        throw new Error("Error log in");
      }
    } catch (err) {
      console.error("login error==>", err);
      toast("error in login");
    }
  };

  const rememberCookie = () => {
    setCookie("username", email, {
      path: "http://localhost:3000",
    });
    setCookie("Password", pass, {
      path: "http://localhost:3000",
    });
    setCookie("remember", remember, {
      path: "http://localhost:3000",
    });
  };
  return (
    <>
      <div className="login-page">
        <div className="login-main">
          <div className="left-section">
            <img src="/Images/icons/zeero-logo.svg" alt="zeero-logo" className="logo" />
            <div class="illustration">
              <img src={csmLanding} alt="Illustration" />
            </div>
          </div>
          <div className="right-section"></div>
        </div>
        <div className="login-wrapper">
          <div className="login">Login</div>
          <form onSubmit={handleSubmit}>
            {/* email  */}
            <div className="input-wrapper item1">
              <label>Email</label>
              <input
                // title=""
                type="email"
                value={email}
                pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your Official Email address"
                required
                autoComplete="on"
              />
            </div>
            {/* password */}
            <div className="input-wrapper item2">
              <label>Password</label>
              <div className="input-item2">
                <input
                  type={password ? "password" : "text"}
                  placeholder="Enter your Password"
                  value={pass}
                  onChange={(e) => setPass(e.target.value)}
                  required
                  autoComplete="on"
                />
                <span>
                  <FontAwesomeIcon
                    onClick={() => setPassword(!password)}
                    icon={password ? faEyeSlash : faEye}
                  />
                </span>
              </div>
            </div>
            {/* remember and forgot option */}
            <div className="remember">
              <span className="remember-text">
                <input
                  type="checkbox"
                  value={remember}
                  id="flexCheckDefault"
                  onChange={(event) => {
                    setRemember(event.target.checked);
                  }}
                  autoComplete="on"
                />
                <label>Remember me?</label>
              </span>
            </div>
            <div className="login-button">
              <button type="submit">Login</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
