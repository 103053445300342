import axios from "axios";
import { CANDIDATE_FOR_CSM } from "./URLS";

export const candidateForTheCsm = async (email, type, currentPage) => {
  console.log("type ==>", type);

  const callStatus =
    type === "Call Pending" ? false : type === "Call Completed" ? true : "";
  // const callStatus = type === "Call Completed";
  const URL = CANDIDATE_FOR_CSM + `?pageNumber=${currentPage - 1}`;
  // console.log("candidateForTheCsm url==>", URL);
  let Config = {};
  const data = {
    email: email,
    callStatus: callStatus,
  };
  return await axios.post(URL, data, Config).then((res) => res.data);
};
