import axios from "axios";
import { CANDIDATE_CALL_STATUS } from "./URLS";

export const candidateCallStatus = async (email) => {
  const URL = CANDIDATE_CALL_STATUS;
  let Config = {};
  const data = {
    name: email,
    status: true,
  };
  return await axios.post(URL, data, Config).then((res) => res.data);
};
