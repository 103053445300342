import axios from "axios";
import { CANDIDATE_PROFILE_DATA_SAVE } from "./URLS";

export const saveCandidateInfo = async (data) => {
  // Remove the email property from the data object
  const { email, ...dataWithoutEmail } = data; // This creates a new object without the email field
  
  const URL = `${CANDIDATE_PROFILE_DATA_SAVE}${data?.email}`;
  let Config = {};

  return await axios.put(URL, dataWithoutEmail, Config).then((res) => res.data);
};

