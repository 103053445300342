import axios from "axios";
import { DOWNLOAD_PDF, VIEW_PDF } from "./URLS";

export const openPdfFromApi = async (jobId) => {
  // Replace 'your_pdf_api_endpoint' with the actual API endpoint that serves the PDF.
  const pdfApiEndpoint = VIEW_PDF;
  const PDF_URL = pdfApiEndpoint + `/${jobId}`;

  // Make an Axios GET request to fetch the PDF content from the API
  return await axios
    .post(PDF_URL, {}, { responseType: "arraybuffer" })
    .then((response) => {
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Create a URL for the blob
      const pdfBlobUrl = window.URL.createObjectURL(blob);

      // Open the PDF in a new tab
      window.open(pdfBlobUrl, "_blank");
    })
    .catch((error) => {
      console.error("Error fetching or opening PDF:", error);
    });
};

export const downloadPdfFromApi = async (jobId) => {
  // Replace 'your_pdf_api_endpoint' with the actual API endpoint that serves the PDF.
  const pdfApiEndpoint = DOWNLOAD_PDF;
  const PDF_URL = pdfApiEndpoint + `/${jobId}`;

  // Make an Axios GET request to fetch the PDF content from the API
  return await axios
    .post(PDF_URL, {}, { responseType: "arraybuffer" })
    .then((response) => {
      const blob = new Blob([response.data], { type: "application/pdf" });

      // Create a URL for the blob
      const pdfBlobUrl = window.URL.createObjectURL(blob);

      // Create a download link
      const link = document.createElement("a");
      link.href = pdfBlobUrl;
      link.download = "downloaded.pdf";

      // Simulate a click on the download link
      link.click();
    })
    .catch((error) => {
      console.error("Error fetching or downloading PDF:", error);
    });
};
