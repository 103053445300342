import { useEffect, useState } from "react";
import "./ApplicationsTable.scss";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

//{/* removed bcz jd has to be downloaded */}
// import { Viewjd } from "../Common/ViewJd/ViewJd";
import { useNavigate } from "react-router-dom";
import { getAllNewJobApplications } from "../../APIS/getAllApplications";
import {
  downloadPdfFromApi,
  openPdfFromApi,
} from "../../APIS/viewAndDownloadJDPdf";

const Headers = [
  "Date of Posting",
  "Job Id",
  "Company Name",
  "Business Unit",
  "Openings",
  "Job Role",
  "Min Rating",
  "Location",
  "Date of Joining",
  // "Status",
  "Action",
];

export const ApplicationsTable = ({
  selectedTypeOfApplication,
  currentPage,
  tableData,
  setTableData,
  handleGetAllApplications,
}) => {
  // const [tableData, setTableData] = useState([...DummyData]);
  // const [tableData, setTableData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([...Headers]);
  // {/* removed bcz jd has to be downloaded */}
  // const [viewJdPage, setViewjdPage] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState("");
  const [selectedIndex, setSelectedIndex] = useState();
  const navigate = useNavigate();

  const handleViewJD = async (jobId) => {
    // Open the job description page for the given job ID.
    // {/* removed bcz jd has to be downloaded */}
    // setSelectedJobId(jobId);
    // setViewjdPage(true);

    try {
      const res = await openPdfFromApi(jobId);
      if (res) {
        console.log("pdf opened ");
      }
    } catch (err) {
      console.log("handleViewJD error==>", err);
    }
  };

  const handleDownLoadJd = async (jobId) => {
    try {
      const res = await downloadPdfFromApi(jobId);
      if (res) {
        console.log("pdf downloaded ");
      }
    } catch (err) {
      console.log("handleViewJD error==>", err);
    }
  };

  const handleSendInvite = (jobId, jobRole, data) => {
    sessionStorage.setItem("jobId", jobId);
    sessionStorage.setItem("jobRole", jobRole);
    sessionStorage.setItem("prefilledData", JSON.stringify(data));
    setSelectedIndex();
    navigate("/send-invites");
  };

  // const handleGetAllApplications = async () => {
  //   try {
  //     // const email = sessionStorage.getItem('email')
  //     const email = "ramyesh.naidu@wissda.com";
  //     const res = await getAllNewJobApplications(
  //       email,
  //       selectedTypeOfApplication,
  //       currentPage
  //     );
  //     // console.log("handleGetAllApplications===>", res);
  //     setTableData([...res]);
  //   } catch (err) {
  //     console.log("handleGetAllApplications error==>", err);
  //     setTableData([]);
  //   }
  // };

  const handleInviteOptions = (index) => {
    setSelectedIndex(index);
  };

  const handleViewInvites = (jobId, jobRole) => {
    // console.log(jobId, jobRole);
    sessionStorage.setItem("jobId", jobId);
    sessionStorage.setItem("jobRole", jobRole);
    navigate("/sent-invites");
  };

  const handleBlurClicked = (e) => {
    console.log(e.target);
    setTimeout(() => {
      setSelectedIndex();
    }, 200);
  };

  const handleDownloadJd = () => {
    console.log("downloaded");
  };

  useEffect(() => {
    const valuesCheck = ["new", "ongoing", "closed"];
    valuesCheck.includes(selectedTypeOfApplication) &&
      handleGetAllApplications();
    // console.log("selected==>", selectedTypeOfApplication);
  }, [selectedTypeOfApplication, currentPage]);

  return (
    <>
      <div className="applicationWrapper">
        <table className="applicationTableContainer">
          <thead className="tableHead">
            <tr className="tableRow">
              {tableHeaders?.map((header, index) => (
                <th key={index} className="headerContent">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="tableBody">
            {tableData?.map((data, index) => (
              <tr key={index}>
                <td>{data?.dateOfPosting}</td>
                <td>{data?.uniqueJobID}</td>
                <td>{data?.companyName}</td>
                <td>{data?.division}</td>
                <td>{data?.numberOfOpenings}</td>
                <td>{data?.jobPosition}</td>
                <td>{data?.minimumCandidateRatings}</td>
                <td>{data?.location}</td>
                <td>{data?.dateOfJoining}</td>
                {/* <td>{data?.status}</td> */}
                <td onClick={() => handleViewJD(data?.uniqueJobID)}>
                  <button className="viewJd">View JD</button>
                </td>
                {selectedTypeOfApplication !== "closed" && (
                  <td onClick={() => handleDownloadJd(data?.uniqueJobID)}>
                    <button
                      className="viewJd"
                      onClick={() => handleDownLoadJd(data?.uniqueJobID)}
                    >
                      Download JD
                    </button>
                  </td>
                )}

                {selectedTypeOfApplication === "closed" && (
                  <td
                    onClick={() =>
                      handleViewInvites(data?.uniqueJobID, data?.jobPosition)
                    }
                  >
                    <button className="viewJd">View Invite</button>
                  </td>
                )}

                {selectedTypeOfApplication !== "closed" && (
                  <td>
                    <div className="invites">
                      <button
                        className="sendButton"
                        onClick={() =>
                          selectedTypeOfApplication === "ongoing"
                            ? handleInviteOptions(index)
                            : handleSendInvite(
                                data?.uniqueJobID,
                                data?.jobPosition,
                                data
                              )
                        }
                        onBlur={(e) => handleBlurClicked(e)}
                      >
                        Invite <ChevronRightIcon />
                      </button>

                      {selectedIndex === index && (
                        <div className="dropdownInvite">
                          <div
                            id="sendInvite"
                            onClick={() =>
                              handleSendInvite(
                                data?.uniqueJobID,
                                data?.jobPosition,
                                data
                              )
                            }
                          >
                            Send Invites
                          </div>
                          <div
                            id="viewInvite"
                            onClick={() =>
                              handleViewInvites(
                                data?.uniqueJobID,
                                data?.jobPosition
                              )
                            }
                          >
                            View Invites
                          </div>
                        </div>
                      )}
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* removed bcz jd has to be downloaded */}
      {/* {viewJdPage && (
        <Viewjd
          JobId={selectedJobId}
          setViewjdPage={setViewjdPage}
          handleSendInvite={handleSendInvite}
        />
      )} */}
    </>
  );
};
