import { useState } from "react";
import "./Header.scss";
import { useNavigate } from "react-router-dom";

export const Header = ({ showDropdowns = true }) => {
  // const [showOptions, setShowOptions] = useState(false);
  const navigate = useNavigate();

  const handleNavigate = (endUrl) => {
    navigate(`${endUrl}`);
  };

  const handleLogoClick = () => {
    if (sessionStorage.getItem("email")) {
      handleNavigate("/dashboard");
    } else {
      // toast('You are not logged in')
      handleNavigate("/");
    }
  };

  const handleLogOut = () => {
    sessionStorage?.removeItem("token");
    sessionStorage?.removeItem("email");
    sessionStorage?.removeItem("role");
    navigate("/");
  };

  return (
    <div className="header-container">
      <div className="wrapper">
        <div className="logo-main" onClick={() => handleLogoClick()}>
          <img src="./Images/zero.logo.svg" alt="" />
        </div>
        {/* {showDropdowns && (
          <div className="account" onMouseEnter={() => setShowOptions(true)}>
            <div>
              <img src={"./account_circle.svg"} alt="" />
            </div>
            <div>
              <img src={"./down-arrow-white.svg"} alt="" />
            </div>
            {showOptions && (
              <div
                className="dropDown"
                onMouseLeave={() => setShowOptions(false)}
              >
                <div onClick={() => handleLogOut()}>Logout</div>
              </div>
            )}
          </div>
        )} */}
        {showDropdowns && (
          <div className="logout" onClick={() => handleLogOut()}>
            Logout
          </div>
        )}
      </div>
    </div>
  );
};
