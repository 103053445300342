const BASE_URL = process.env.REACT_APP_BASE_URL;

// const CSM_SERVICE = ":8401";
// const ROUND5 = ":8105";
// const CLSM_SERVICE = ":8402";
// const DEVELOPER = ":8100";
// const AUTHENTICATION = ":8301"

const CSM_SERVICE = "/csm";
const ROUND5 = "/round5";
const CLSM_SERVICE = "/clsm";
const DEVELOPER = "/developer";
const AUTHENTICATION = "/authentication"
const CLIENT="/client"


export const GET_ALL_APPLICATIONS = `${BASE_URL}${CSM_SERVICE}/api/v1/candidatesm/filter`;
export const CANDIDATE_FOR_SEND_INVITE = `${BASE_URL}${CSM_SERVICE}/api/v1/candidatesm/jobfilter`;
export const CONFIRM_INVITE_SEND = `${BASE_URL}${CSM_SERVICE}/api/v1/candidatesm/jobpush`;
export const CANDIDATE_FOR_CSM = `${BASE_URL}${CSM_SERVICE}/api/v1/candidatesm/get/candidates/details`;
export const CANDIDATE_CALL_STATUS = `${BASE_URL}${ROUND5}/api/v1/candidatesm/set/status`;
export const CANDIDATE_PROFILE = `${BASE_URL}${CSM_SERVICE}/api/v1/candidatesm/developer/profile`;
export const INVITE_DATA = `${BASE_URL}${CSM_SERVICE}/api/v1/candidatesm/get/invite/details`;
export const CHANGE_CANDIDATE_STATUS = `${BASE_URL}${CSM_SERVICE}/api/v1/candidatesm/change/invitestatus`;
export const VIEW_PDF = `${BASE_URL}${CLSM_SERVICE}/api/v1/clientsm/view/job/description`;
export const DOWNLOAD_PDF = `${BASE_URL}${CLSM_SERVICE}/api/v1/clientsm/download/job/description/file`;
export const INITIALIZE_JOB_HANDLE = `${BASE_URL}${CSM_SERVICE}/api/v1/candidatesm/add/jobhandle`;
export const HANDLE_INVITE = `${BASE_URL}${CSM_SERVICE}/api/v1/candidatesm/change/availability`;
export const GET_COUNTRIES = `${BASE_URL}${DEVELOPER}/api/v1/country/getcountries`;
export const REFRESH_OFAC_STATUS = `${BASE_URL}${DEVELOPER}/api/v1/ofac/refresh`;
export const CSM_LOGIN = `${BASE_URL}${AUTHENTICATION}/api/v1/user/login/role`;
export const CSM_SEARCH_APPLICATIONS = `${BASE_URL}${CSM_SERVICE}/api/v1/candidatesm/filter`;
export const  CANDIDATE_CARD_INFO= `${BASE_URL}${CLIENT}/api/v1/client/landingpage/csm/get`;
export const CANDIDATE_PROFILE_DATA= `${BASE_URL}${DEVELOPER}/api/v1/dev/profile/csm/get?email=`;
export const CANDIDATE_PROFILE_DATA_SAVE= `${BASE_URL}${DEVELOPER}/api/v1/dev/profile/update?email=`;
// http://localhost:8100/developer/api/v1/dev/profile/csm/get?email=testswati02@gmail.com

// endpoint for above api => /new?pageNumber=0
