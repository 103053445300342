import axios from "axios";
import { GET_ALL_APPLICATIONS } from "./URLS";

export const getAllNewJobApplications = async (email, type, currentPage) => {
  const endPoint = `/${type}` + `?pageNumber=${currentPage - 1}`;
  const URL = GET_ALL_APPLICATIONS + endPoint;
  let Config = {};
  const data = {
    email: email,
  };
  return await axios.post(URL, data, Config).then((res) => res.data);
};
