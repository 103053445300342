import { useEffect, useState } from "react";
import Popup from "../popUp/popUp";
import "./CandidateProfile.scss";
import { getCandidateProfileDetails } from "../../../APIS/candidateProfile";
import Avatar from "@mui/material/Avatar";
export const CandidateProfile = ({ candidateId, setViewProfile }) => {
  return (
    <Popup>
      <CandidateProfileUI
        candidateId={candidateId}
        setViewProfile={setViewProfile}
      />
    </Popup>
  );
};

const CandidateProfileUI = ({ candidateId, setViewProfile }) => {
  const [candidate, setCandidate] = useState({});

  const getCandidateDetails = async () => {
    try {
      const res = await getCandidateProfileDetails(candidateId);
      // console.log("getCandidateDetails =>", res);
      setCandidate({ ...res });
    } catch (err) {
      console.log("getCandidateDetails error ==>", err);
    }
  };

  useEffect(() => {
    getCandidateDetails();
  }, []);

  return (
    <div className="candidateProfileWrapper">
      <div onClick={() => setViewProfile(false)} className="cross">
        <img src={"./Images/icons/Close.svg"} alt="cut" />
      </div>
      {/* <div>{candidateId}</div> */}
      <div className="title">Candidate Profile</div>
      <div className="basic">
        <div className="avatar">
          {/* dummy avatar in mui */}
          <Avatar
            sx={{
              width: 80,
              height: 80,
              backgroundColor: "gray",
            }}
          />
          <div className="Available">
            <span></span> Available
          </div>
          <div className="availability">{candidate?.availability}</div>
        </div>
        <div className="nameAndDetails">
          <div>{candidate?.candidateName}</div>
          <div>
            Email ID : <span className="email">{candidate?.email}</span>
          </div>
          <div>
            Mobile : <span>{candidate?.mobile}</span>
          </div>
          <div>
            Location : <span>{candidate?.location}</span>
          </div>
        </div>
      </div>
      <div className="assessments">
        <div>Assessment Results</div>
        <div className="allRatings">
          <div>Overall</div>
          <div>
            Score : <span> {candidate?.assessmentRating?.overallScore}</span>
          </div>
          <div>Round 1: Technical Fundamentals Test/Quiz (TFT/Q)</div>
          <div>
            Score : <span> {candidate?.assessmentRating?.round1Score}</span>
          </div>
          <div>Round 2: Coding Challenge</div>
          <div>
            Score : <span> {candidate?.assessmentRating?.round2Score}</span>
          </div>

          <div>Round 3: Interpersonal Skills Discussion</div>
          <div>
            Score : <span> {candidate?.assessmentRating?.round3Score}</span>
          </div>

          <div>Round 4: Practical Assignment</div>
          <div>
            Score : <span> {candidate?.assessmentRating?.round4Score}</span>
          </div>

          <div>Round 5: Expert Discussion</div>
          <div>
            Score : <span> {candidate?.assessmentRating?.round5Score}</span>
          </div>
        </div>
      </div>
      <div className="allSkills">
        <div>SKILLS</div>
        <div>
          {candidate?.skills?.map((item, index) => (
            <div key={index} className="skillsDetails">
              {item?.languages?.map((subItem, subIndex) => (
                <div key={subIndex} className="eachItem">
                  <div className="languageAndProf">
                    <div>{subItem?.language}</div>
                    <div>{subItem?.proficiency}</div>
                  </div>

                  <div>
                    {subItem?.frameworks?.map((frameworks, frameIndexes) => (
                      <div key={frameIndexes} className="frameworks">
                        <div>{frameworks?.framework}</div>
                        <div>{frameworks?.proficiency}</div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
