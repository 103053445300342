import { useEffect, useState } from "react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import SearchIcon from "@mui/icons-material/Search";
import "./DashboardCdsm.scss";
import { ApplicationsTable } from "../../Components/ApplicationsTable/ApplicationsTable";
import { CandidateTable } from "../../Components/CandidateTable/CandidateTable";
import Pagination from "@mui/material/Pagination";
import { initializeJobHandle } from "../../APIS/initializeJobHandle";
import { CSM_SEARCH_APPLICATIONS } from "../../APIS/URLS";
import { toast } from "react-toastify";
import axios from "axios";
import { getAllNewJobApplications } from "../../APIS/getAllApplications";
import { Header } from "../../Components/Header/Header";

const accountData = [
  {
    color: "#00532D",
    background: "#7DE3B4",
    line1: "Total no of candidates assigned",
    line2: "123",
  },
  {
    color: "#CB5600",
    background: "#FFF1E8",
    line1: "New candidates assigned",
    line2: "456",
  },
  {
    color: "#4917B4",
    background: "#EDECFA",
    line1: "No of jobs offered",
    line2: "789",
  },
];

const toggleValues = [
  { title: "Applications", optionsForDashboard: ["new", "ongoing", "closed"] },
  {
    title: "Candidates",
    optionsForDashboard: ["Call Pending", "Call Completed"],
  },
];

export const DashboardCdsm = () => {
  // name of the customer suport manager
  const [name, setName] = useState("");
  //   data in the top card of dashboard,
  const [cardsData, setCardsData] = useState([...accountData]);
  //   the three options to display data populated in the dashboard table
  const [typeOfDataToDisplay, setTypeOfDataToDisplay] = useState([]);
  //   options in toggle button
  const [toggleOptions, setToggleOptions] = useState([...toggleValues]);
  //   value selected by the toggle button
  const [alignment, setAlignment] = useState(toggleOptions[0]?.title);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableData, setTableData] = useState([]);

  const [selectedTypeOfApplication, setSelectedTypeOfApplication] =
    useState("");

  //   when toggle button is changed this is called
  const handleToggleButtonChange = (event, value) => {
    setAlignment(value);
  };

  //   when the dashboard filter is changed for application or candidate
  const handleApplicationTypeChange = (value) => {
    setSelectedTypeOfApplication(value);
  };

  //   set the options for type of type of data to display
  const handleTypeOfDataToDisplay = () => {
    const position = toggleOptions.findIndex(
      (item) => item?.title === alignment
    );
    setTypeOfDataToDisplay([...toggleValues[position]?.optionsForDashboard]);
    setSelectedTypeOfApplication(
      toggleValues[position]?.optionsForDashboard[0]
    );
  };

  const handleGetAllApplications = async () => {
    try {
      const email = sessionStorage.getItem("email");
      const res = await getAllNewJobApplications(
        email,
        selectedTypeOfApplication,
        currentPage
      );
      // console.log("handleGetAllApplications===>", res);
      setTableData([...res]);
    } catch (err) {
      console.log("handleGetAllApplications error==>", err);
      setTableData([]);
    }
  };

  //   everytime toggle is chaged the optiosn in type of data to display will change
  useEffect(() => {
    handleTypeOfDataToDisplay();
  }, [alignment]);

  useEffect(() => {
    // console.log("initialize");
    initializeJobHandle();
  }, []);

  return (
    <>
      <Header showDropdowns={true} />

      <div className="dashboardWrapper">
        <div className="welcome">Welcome! {name}</div>
        <div className="accountDetails">
          {cardsData?.map((item, index) => (
            <AccountCard key={index} data={item} />
          ))}
        </div>
        <div className="toggleAndInput">
          <ToggleButtons
            options={toggleOptions}
            alignment={alignment}
            setAlignment={setAlignment}
            handleToggleButtonChange={handleToggleButtonChange}
          />
          {alignment === "Applications" && (
            <SearchInput
              selectedTypeOfApplication={selectedTypeOfApplication}
              currentPage={currentPage}
              setTableData={setTableData}
              handleGetAllApplications={handleGetAllApplications}
            />
          )}
        </div>
        <TypeOfApplication
          applicationOptions={typeOfDataToDisplay}
          choosen={selectedTypeOfApplication}
          handleApplicationTypeChange={handleApplicationTypeChange}
        />
        {alignment === "Applications" && (
          <ApplicationsTable
            selectedTypeOfApplication={selectedTypeOfApplication}
            currentPage={currentPage}
            tableData={tableData}
            setTableData={setTableData}
            handleGetAllApplications={handleGetAllApplications}
          />
        )}
        {alignment === "Candidates" && (
          <CandidateTable
            selectedTypeOfApplication={selectedTypeOfApplication}
            currentPage={currentPage}
          />
        )}
        <PagesAndCount
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
};

const AccountCard = ({ data }) => {
  return (
    <div className="account">
      <div style={{ background: data?.background }} className="circle">
        <AccountCircleIcon
          style={{
            color: data?.color,
            background: "#fff",
            borderRadius: "50%",
          }}
        />
      </div>

      <div className="text">
        <div>{data?.line1}</div>
        <div>{data?.line2}</div>
      </div>
    </div>
  );
};

const ToggleButtons = ({
  options,
  alignment,
  setAlignment,
  handleToggleButtonChange,
}) => {
  const handleChange = (event, value) => {
    handleToggleButtonChange(event, value);
  };

  return (
    <div className="toggleButtons">
      <ToggleButtonGroup
        value={alignment}
        exclusive
        onChange={handleChange}
        className="my-toggle-button-group"
        sx={{ backgroundColor: "blue" }}
      >
        {options?.map((item, index) => (
          <ToggleButton
            key={index}
            value={item?.title}
            style={{
              backgroundColor: alignment === item?.title ? "#4285F4" : "",
            }}
          >
            {item?.title}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </div>
  );
};

const SearchInput = ({
  selectedTypeOfApplication,
  currentPage,
  setTableData,
  handleGetAllApplications,
}) => {
  const [searchInput, setSearchInput] = useState("");
  let [callTimer, setCallTimer] = useState("");

  // Function to debounce another function
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(callTimer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, delay);
      setCallTimer(timer);
    };
  };

  // Function to make an API call
  const fetchApiData = async (query) => {
    try {
      const endpoint = `${CSM_SEARCH_APPLICATIONS}/${selectedTypeOfApplication}?pageNumber=${
        currentPage - 1
      }`;

      const queryParams = {};

      if (query.length > 0) {
        // Use a regular expression to check if the first and last character is a number
        if (/^[0-9].*[0-9]$/.test(query)) {
          queryParams["jobIdSearch"] = parseInt(query);
        }
        // Use a regular expression to check if the first character is a number and the last character is a letter
        else if (/^[0-9].*[a-zA-Z]$/.test(query)) {
          throw new Error("Input should be either name or number");
        } else {
          queryParams["companyNameSearch"] = query;
        }
      } else {
        return;
      }

      queryParams["email"] = sessionStorage.getItem("email");

      const response = await axios.post(endpoint, queryParams);

      // console.log(response?.data);
      if (response?.data) {
        setTableData([...response?.data]);
      }
    } catch (error) {
      console.error("Error fetching data: ==>", error);
      toast(error?.message);
    }
  };

  // Debounced version of the API call function
  const debouncedFetchApiData = debounce(fetchApiData, 500);

  const handleSearch = (val) => {
    // Implement your search logic here
    // console.log(val);
    fetchApiData(val);
  };

  useEffect(() => {
    if (searchInput) {
      // Call the debounced change handler when the value changes
      debouncedFetchApiData(searchInput);
    } else {
      handleGetAllApplications();
    }
    return () => clearTimeout(callTimer);
  }, [searchInput]); // Effect runs when `value` changes

  return (
    <div className="search">
      <div className="inputItem">
        <input
          type="text"
          value={searchInput}
          placeholder="Search by Company or Job ID"
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>
      <div>
        <SearchIcon
          onClick={() => handleSearch(searchInput)}
          style={{ cursor: "pointer" }}
        />
      </div>
    </div>
  );
};

const TypeOfApplication = ({
  applicationOptions,
  choosen,
  handleApplicationTypeChange,
}) => {
  const handleChange = (value) => {
    handleApplicationTypeChange(value);
  };

  return (
    <div className="application">
      {applicationOptions?.map((item, index) => (
        <div
          key={index}
          className={choosen === item ? "active" : ""}
          onClick={() => handleChange(item)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

const PagesAndCount = ({ currentPage, setCurrentPage }) => {
  // const [currentPage, setCurrentPage] = useState(1);
  // const [itemsPerPage, setItemsPerPage] = useState(10);

  // const handleChange = (event) => {
  //   setItemsPerPage(event.target.value);
  // };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  return (
    <div className="pagesAndCount">
      {/* <div className="itemsPerPage">
        <span>Items per page</span>
        <Select
          value={itemsPerPage}
          onChange={handleChange}
          className="selectClass"
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={20}>20</MenuItem>
          <MenuItem value={30}>30</MenuItem>
          <MenuItem value={50}>50</MenuItem>
        </Select>
      </div> */}
      <div className="pageNo">
        <Pagination
          page={currentPage}
          onChange={handlePageChange}
          count={100}
          className="pages"
        />
      </div>
      {/* <div className="itemCount">
        Showing <span>1-10 of 200</span>
      </div> */}
    </div>
  );
};
